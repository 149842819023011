import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Center = styled.div`
  margin: auto;
  text-align: center;
  max-width: 400px;
`;
const Justify = styled.p`
  text-align: justify;
`;

function AboutPage() {
  return (
    <Layout breakCrumbs={[{label: "Giới thiệu về chúng tôi"}]}>
      <SEO title="Giới thiệu về chúng tôi" />
      <Center>
        <h1>Tính 1s</h1>
        <br />
        <h4>Đi lên từ những điều nhỏ nhất</h4>
        <br />
        <Justify>
          Từ những con người thường hay cảm thấy rắc rối khi phải dùng máy tính và không đặc biệt
          yêu thích những con số. Đội ngũ Tính 1s chúng tôi thấu hiểu được khó khăn của bạn khi có
          nhiều vấn đề trong cuộc sống bạn không biết chúng được tính ra làm sao và phải tốn rất
          nhiều thời gian mày mò công thức khắp các trang web của Google. Chúng tôi xây dựng
          Tinh1s.com để giúp bạn giải quyết những phiền não đó.
        </Justify>
        <br />
        <p>Tính 1s được thiết kế bởi: TechArtVN</p>
      </Center>
    </Layout>
  );
}

export default AboutPage;
